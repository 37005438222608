/* margin properties */
.mt-0 {
	margin-top: 0px !important;
}
/* padding properties */
.pr-0 {
	padding-right: 0px !important;
}
.pt-0 {
	padding-top: 0px !important;
}
/* flexbox properties */
.d-flex {
	display: flex;
}
.justify-end {
	justify-content: end !important;
}
.cursor-pointer {
	cursor: pointer;
}
.break-all {
	word-break: break-all;
}
